import React from "react";
import styles from "./Cta.module.scss";
import Button from "../elements/Button";
import BackgroundPattern from "../elements/BackgroundPattern";
import FadeUp from "../effects/FadeUp";
import Section from "../layout/Section";
import Row from "../layout/Row";
import Container from "../layout/Container";

const Cta = ({text, link1, link2, theme}) => {
    return text || (link1 && link1.url) || (link2 && link2.url) ? (
        <Section className={styles.cta} theme={theme}>

            {theme === "brightscan-dark" || theme === "torus-dark" ?
                <BackgroundPattern theme={theme} />
            : null}

            <Container>
                <Row>
                    <FadeUp className={styles.cta__content}>
                        {text ? <h3>{text}</h3> : null}
                        {link1 || link2 ?
                            <div>
                                {link1 && link1.url ? 
                                    <Button url={link1.url} text={link1.text} external={link1.target === "_blank"} filledStyle={true} altColors={true} />
                                    : null
                                }
                                {link2 && link2.url?
                                    <Button url={link2.url} text={link2.text} external={link2.target === "_blank"} altColors={true} />
                                    : null
                                }
                            </div>
                        : null}
                    </FadeUp>
                </Row>
            </Container>
        </Section>
    ) : null;
};

export default Cta;