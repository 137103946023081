import React from "react";

const FacebookCircle = () => {
    return (
        <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg" className="facebook-icon">
            <path d="M18.022 0.681641C8.99969 0.681641 1.67969 8.00164 1.67969 17.024C1.67969 26.0463 8.99969 33.3663 18.022 33.3663C27.0443 33.3663 34.3643 26.0463 34.3643 17.024C34.3643 8.00164 27.0443 0.681641 18.022 0.681641ZM21.9374 11.917H19.5541C19.2136 11.917 18.8732 12.2575 18.8732 12.7681V14.4705H21.9374L21.4267 17.1942H18.8732V24.8547H15.9792V17.1942H13.2555V14.6407H15.809V13.1086C15.809 10.8956 17.3411 9.19327 19.3839 9.19327H21.7671V11.917H21.9374Z"/>
        </svg>
    );
};

export default FacebookCircle;