import React, {useState} from "react";
import styles from "./FadeUp.module.scss";
import 'intersection-observer';
import { InView } from 'react-intersection-observer';

const FadeUp = ({children, threshold, rootMargin, delay, time, inline, disableDelayMobile, stayOpaque, className, style, as}) => {
    const [hide, setHide] = useState(true);
    
    return (
        <InView
            className={`
                ${className}
                ${styles.wrapper}
                ${hide ? "" : styles.wrapperShow}
                ${inline ? styles.wrapperInline : ""}
                ${!disableDelayMobile ? styles.enableDelay : ""}
                ${stayOpaque ? styles.wrapperStayOpaque : ""}`
            }
            style={{...style, "--time": time ? time : "1.9s", "--delay": delay ? delay : "0"}}
            threshold={threshold !== undefined ? threshold : 0.1}
            rootMargin={rootMargin !== undefined ? rootMargin : "-50px"}
            as={as ? as : "div"}
            onChange={inView => {
                if(hide) setHide(!inView);
            }}
        >
            {children}
        </InView>
    );
};

export default FadeUp;
