import React from "react"
import styles from "./Button.module.scss"
import LinkTo from "../LinkTo"
import External from "../../../svgs/External"

const Button = ({
    text,
    url,
    external,
    target,
    simpleStyle,
    filledStyle,
    fillStyleSecondary,
    fillStyleIcon,
    fillStyleReverse,
    backgroundFilled,
    header,
    altColors,
    altColorsHover,
    fake,
    className,
    buttonClassName,
}) => {
    return text && url ? (
        <div
            className={`${className} ${styles.buttonWrapper} ${
                !simpleStyle ? styles.buttonWrapperStyled : ""
            }`}
        >
            <LinkTo
                className={`
                    ${buttonClassName}
                    ${styles.button}
                    ${simpleStyle ? "" : styles.buttonStyled}
                    ${filledStyle ? styles.buttonFilled : ""}
                    ${fillStyleSecondary ? styles.buttonFilledSecondary : ""}
                    ${fillStyleIcon ? styles.buttonFilledIcon : ""}
                    ${backgroundFilled ? styles.buttonBgFilled : ""}
                    ${altColors ? styles.buttonAltColors : ""}
                    ${altColorsHover ? styles.buttonAltColorsHover : ""}
                    ${fillStyleReverse ? styles.buttonFilledReverse : ""}
                    ${header ? styles.buttonHeader : ""}
                `}
                url={url}
                external={external}
                target={target}
                fake={fake}
            >
                {text}
                {simpleStyle && external ? (
                    <span className={styles.linkSvg}>
                        &nbsp;
                        <External />
                    </span>
                ) : null}
            </LinkTo>
        </div>
    ) : null
}

export default Button
