import React from "react"
import styles from "./CategoryButton.module.scss"
import LinkTo from "../LinkTo"

const CategoryButton = ({
    text,
    url,
    external,
    target,
    fake,
    className,
    buttonClassName,
    isActive,
}) => {
    return text && url ? (
        <div className={`${className} ${styles.buttonWrapper}`}>
            <LinkTo
                className={`
                    ${buttonClassName}
                    ${styles.button}
                    ${isActive ? styles.buttonActive : ""}
                `}
                url={url}
                external={external}
                target={target}
                fake={fake}
                activeClassName={styles.buttonActive}
            >
                {text}
            </LinkTo>
        </div>
    ) : null
}

export default CategoryButton
