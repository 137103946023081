import React, {useState, useEffect, useRef} from "react";
import styles from "./NavItem.module.scss";
import LinkTo from "../../../elements/LinkTo";
import External from "../../../../svgs/External";
import Chevron from "../../../../svgs/Chevron";

const NavItem = ({link, external, showExternalIcon, isMobile, isSubNav, handleNavigate, popDown, title}) => {
    const childrenContainerRef = useRef();

    const [open, setOpen] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);

    useEffect(() => {
        handleResize();

        window.addEventListener("resize", handleResize);

        return (() => {
            window.removeEventListener("resize", handleResize);
        })
    }, []);

    const handleClick = (e) => {
        e.preventDefault();
        const contentScrollHeight = childrenContainerRef && childrenContainerRef.current ? childrenContainerRef.current.scrollHeight : 0;
        setContentHeight(contentScrollHeight);
        setOpen(!open);
    }

    const handleResize = () => {
        const contentScrollHeight = childrenContainerRef && childrenContainerRef.current ? childrenContainerRef.current.scrollHeight : 0;
        setContentHeight(contentScrollHeight);
    }

    const navItems = link && link.children && link.children.length ? link.children.map((item, i) => {
        return item.uri ? (
            <li key={i}>
                <LinkTo
                    url={item.externalLink ? item.externalLink.url : item.uri}
                    className={styles.navDropdownLink}
                    external={item.externalLink}
                    target={item.externalLink ? "_blank" : ""}
                    onClick={handleNavigate}
                >
                    {item.title}{item.externalLink ? (<span className={styles.linkSvg}>&#8239;<External /></span>) : null}
                </LinkTo>
            </li>
        ) : null;
    }) : null;

    return link && (link.uri || link.url) ? (
        <li className={`${styles.navLinks} ${open ? styles.open : ""} ${isMobile ? styles.isMobile : styles.isDesktop} ${isSubNav ? styles.subNav : styles.mainNav} ${popDown ? styles.navLinksPopDown : ""}`}>
            <div>
                <div className={styles.navLinks__parent}>
                    <LinkTo
                        url={link.uri ? link.uri : link.url}
                        external={external}
                        target={link.target}
                        onClick={handleNavigate}
                    >
                        {title ?? (link.title ? link.title : link.text)}{showExternalIcon ? (<span className={styles.linkSvg}>&#8239;<External /></span>) : null}
                    </LinkTo>
                    {navItems ? <button onClick={handleClick} aria-label="Open sub-menu"><Chevron /></button> : null}
                </div>
                {navItems ? (
                    <div ref={childrenContainerRef} style={{height: open ? `${contentHeight}px` : 0}} className={styles.dropdown}>
                        <ul>
                            {navItems}
                        </ul>
                    </div>
                ) : null}
            </div>
        </li>
    ) : null;
}

export default NavItem;