import React from "react"

const LogoIcon = () => {
    return (
        <svg
            width="156"
            height="160"
            viewBox="0 0 156 160"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="logo-icon"
        >
            <path d="M17.1604 119.88H35.6504H102.89V119.82L93.7104 103.92H16.4904L16.5504 93.5296C16.6456 80.3695 20.087 67.4499 26.5509 55.9862C33.0149 44.5225 42.2889 34.8916 53.5004 27.9996L69.5004 0.349609L47.4004 12.9996C33.2656 21.1493 21.5068 32.8526 13.2905 46.9487C5.07417 61.0449 0.685715 77.0441 0.56039 93.3596L0.400391 119.86L17.1604 119.88Z" />
            <path d="M35.2695 89.1091H53.8595L77.9395 47.2891L77.9995 47.1691L87.1495 31.2891L87.2295 31.1491L92.3695 22.2191L104.75 29.6091C115.35 36.6105 124.069 46.108 130.14 57.2672C136.211 68.4264 139.449 80.9058 139.57 93.6091L154.27 118.999L154.78 119.889L155.53 121.199V119.889L155.6 94.4191C155.606 78.1022 151.349 62.0668 143.248 47.9026C135.148 33.7384 123.486 21.9373 109.42 13.6691L86.5495 0.289062L82.6295 7.08906L77.9995 15.0891L77.8895 15.2691L68.7495 31.0891L35.2695 89.1091Z" />
            <path d="M129.21 103.87L95.55 45.5898L95.49 45.6898L86.31 61.5898L110.72 103.85L119.9 119.75L119.96 119.85L124.96 128.49L115.96 133.63C104.558 140.101 91.7015 143.575 78.5921 143.727C65.4828 143.879 52.549 140.704 41 134.5H9L30.78 147.15C44.9046 155.311 60.9168 159.639 77.2295 159.706C93.5422 159.773 109.589 155.575 123.78 147.53L146.78 134.41L138.38 119.87L129.21 103.87Z" />
        </svg>
    )
}

export default LogoIcon
