import React, { useRef, useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "../Header"
import HeaderPopDown from "../HeaderPopDown"
import styles from "./Hero.module.scss"
import BackgroundPattern from "../elements/BackgroundPattern"
import BackgroundImage from "../elements/BackgroundImage"
import GatsbyImage from "gatsby-image"
import generateFluid from "../../util/generateFluid"
import FadeUp from "../effects/FadeUp"
import { useWebGLContext } from "../contexts/WebGLContext"
import GradientButton from "../elements/GradientButton"
import Button from "../elements/Button"
import Container from "../layout/Container"
import Row from "../layout/Row"
import Section from "../layout/Section"
import CategoryButton from "../elements/CategoryButton"

const Hero = ({
    logo,
    smallLogo,
    image,
    mobileImage,
    productName,
    pageTitle,
    poweredByImage,
    operatingSystemLogos,
    operatingSystemsLabel,
    altImageLayout,
    direction,
    type,
    title,
    bgImage,
    description,
    primaryCta,
    secondaryCta,
    tabs,
    activeTab,
    useBgPattern,
    extended,
    animation,
    theme,
    productColor,
    howItWorksRef,
    updatePageState,
}) => {
    const webglContext = useWebGLContext()
    const coverRef = useRef()

    const maxHeroMainBgOpacity = 0.7
    const [heroMainBgOpacity, setHeroMainBgOpacity] = useState(
        maxHeroMainBgOpacity
    )

    const { allFile } = useStaticQuery(
        graphql`
            {
                allFile(filter: { name: { eq: "globe" } }) {
                    edges {
                        node {
                            childImageSharp {
                                fluid(maxWidth: 3072) {
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                            }
                        }
                    }
                }
            }
        `
    )

    const globeImageFluidData =
        allFile.edges[0] && allFile.edges[0].node.childImageSharp.fluid

    const handleScroll = () => {
        const { scrollY, innerHeight: wh } = window
        const progress = scrollY / wh

        setHeroMainBgOpacity(maxHeroMainBgOpacity - progress * 2)
    }

    useEffect(() => {
        const { data, setData } = webglContext

        data.cover = coverRef.current

        setData({ ...data })

        window.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    const contentStandard = (
        <div className={`${styles.hero} ${styles.heroStandard}`}>
            <Container extended={true}>
                <Row>
                    <div className={styles.heroStandard__content}>
                        {title ? (
                            <FadeUp className="h1">
                                <h1>{title}</h1>
                            </FadeUp>
                        ) : null}
                    </div>
                </Row>
            </Container>
        </div>
    )

    const contentMain = (
        <div className={`${styles.hero} ${styles.heroMain}`}>
            <Container extended={true}>
                <Row>
                    <div className={styles.heroMain__content}>
                        {title ? (
                            <FadeUp className="h1">
                                <h1>{title}</h1>
                            </FadeUp>
                        ) : null}
                        {description ? (
                            <FadeUp delay="0.1s" className="p">
                                <p className="masthead-p">{description}</p>
                            </FadeUp>
                        ) : null}
                        {(primaryCta && primaryCta.url) ||
                        (secondaryCta && secondaryCta.url) ? (
                            <FadeUp delay="0.2s">
                                {primaryCta && primaryCta.url ? (
                                    <GradientButton
                                        text={primaryCta.text}
                                        url={primaryCta.url}
                                        external={
                                            primaryCta.target === "_blank"
                                        }
                                        target={primaryCta.target}
                                        primaryStyle={true}
                                        className={`${styles.button}`}
                                    />
                                ) : null}
                                {secondaryCta && secondaryCta.url ? (
                                    <GradientButton
                                        text={secondaryCta.text}
                                        url={secondaryCta.url}
                                        external={
                                            secondaryCta.target === "_blank"
                                        }
                                        target={secondaryCta.target}
                                        secondaryStyle={true}
                                        className={`${styles.button}`}
                                    />
                                ) : null}
                            </FadeUp>
                        ) : null}
                    </div>
                </Row>
            </Container>
        </div>
    )

    const contentProductLanding = (
        <div className={`${styles.hero} ${styles.heroProductLanding}`}>
            <Container extended={true}>
                <Row
                    verticalCenter={true}
                    className={styles.heroProductLanding__row}
                >
                    <div className={styles.heroProductLanding__content}>
                        {title ? (
                            <FadeUp className="h1">
                                <h1>{title}</h1>
                            </FadeUp>
                        ) : null}
                        {description ? (
                            <FadeUp delay="0.1s" className="p">
                                <p className="masthead-p">{description}</p>
                            </FadeUp>
                        ) : null}
                        {(primaryCta && primaryCta.url) ||
                        (secondaryCta && secondaryCta.url) ? (
                            <FadeUp delay="0.2s">
                                {primaryCta && primaryCta.url ? (
                                    <Button
                                        text={primaryCta.text}
                                        url={primaryCta.url}
                                        external={
                                            primaryCta.target === "_blank"
                                        }
                                        target={primaryCta.target}
                                        filledStyle={true}
                                        altColors={true}
                                        className={`${styles.button}`}
                                    />
                                ) : null}
                                {secondaryCta && secondaryCta.url ? (
                                    <Button
                                        text={secondaryCta.text}
                                        url={secondaryCta.url}
                                        external={
                                            secondaryCta.target === "_blank"
                                        }
                                        target={secondaryCta.target}
                                        className={`${styles.button}`}
                                    />
                                ) : null}
                            </FadeUp>
                        ) : null}
                    </div>
                    <div className={styles.heroProductLanding__image}>
                        <FadeUp
                            delay="0.3s"
                            threshold="0"
                            rootMargin="0px"
                            disableDelayMobile={true}
                        >
                            {image && image.length ? (
                                <GatsbyImage
                                    fluid={[
                                        generateFluid(
                                            image[0],
                                            "(min-width: 2200px) 37vw, (min-width: 1680px) 973px, (min-width: 600px) 59vw, 100vw",
                                            "",
                                            true
                                        ),
                                    ]}
                                    loading="eager"
                                    className={
                                        styles.heroProductLanding__image__img
                                    }
                                />
                            ) : null}
                        </FadeUp>
                    </div>
                </Row>
            </Container>
        </div>
    )

    const operatingSystemLogosElements =
        operatingSystemLogos && operatingSystemLogos.length
            ? operatingSystemLogos.map((item, i) => {
                  return item.contents ? (
                      <span
                          dangerouslySetInnerHTML={{ __html: item.contents }}
                          key={i}
                      />
                  ) : null
              })
            : null

    const aspectRatioImage =
        image && image[0] ? image[0].width / image[0].height : 1
    const aspectRatioImageMobile =
        mobileImage && mobileImage[0]
            ? mobileImage[0].width / mobileImage[0].height
            : aspectRatioImage

    const contentProduct = (
        <div className={`${styles.hero} ${styles.heroProduct}`}>
            <Container extended={true}>
                <div
                    className={`${styles.hero__details} ${
                        direction === "right" ? styles.hero__detailsReverse : ""
                    }`}
                >
                    <div className={styles.hero__details__content}>
                        <div>
                            {productName ? (
                                <FadeUp>
                                    <h6>{productName}</h6>
                                </FadeUp>
                            ) : null}
                            {title ? (
                                <FadeUp
                                    delay="0.1s"
                                    className={`h3 ${styles.hero__details__content__heading}`}
                                >
                                    <h3>{title}</h3>
                                </FadeUp>
                            ) : null}
                            <FadeUp delay="0.2s" className={styles.ctaButtons}>
                                {primaryCta && primaryCta.url ? (
                                    <Button
                                        url={primaryCta.url}
                                        target={primaryCta.target}
                                        external={
                                            primaryCta.target === "_blank"
                                        }
                                        text={primaryCta.text}
                                        filledStyle={true}
                                        altColors={true}
                                        className={styles.button}
                                    />
                                ) : null}
                                {secondaryCta && secondaryCta.url ? (
                                    <Button
                                        url={secondaryCta.url}
                                        target={secondaryCta.target}
                                        external={
                                            secondaryCta.target === "_blank"
                                        }
                                        text={secondaryCta.text}
                                        className={styles.button}
                                    />
                                ) : null}
                            </FadeUp>
                            {poweredByImage && poweredByImage.length ? (
                                <FadeUp
                                    className={
                                        styles.hero__details__content__poweredByImageWrapper
                                    }
                                    threshold="0"
                                    rootMargin="0px"
                                    delay="0.3s"
                                >
                                    <GatsbyImage
                                        fluid={generateFluid(
                                            poweredByImage[0],
                                            "(min-width: 2200px) 32vw, (min-width: 1680px) 503px, (min-width: 600px) 29vw, 100vw",
                                            "",
                                            true
                                        )}
                                        loading="eager"
                                        className={
                                            styles.hero__details__content__poweredByImage
                                        }
                                        style={{
                                            "--aspect-ratio": `${
                                                poweredByImage[0].width /
                                                poweredByImage[0].height
                                            }`,
                                        }}
                                    />
                                </FadeUp>
                            ) : null}
                            {operatingSystemLogosElements ||
                            operatingSystemsLabel ? (
                                <FadeUp
                                    delay="0.3s"
                                    className={styles.operatingSystems}
                                    threshold="0"
                                    rootMargin="0px"
                                >
                                    <p className="sub">
                                        {operatingSystemsLabel
                                            ? operatingSystemsLabel
                                            : null}
                                    </p>
                                    <div
                                        className={
                                            styles.operatingSystems__icons
                                        }
                                    >
                                        {operatingSystemLogosElements}
                                    </div>
                                </FadeUp>
                            ) : null}
                        </div>
                    </div>
                    <FadeUp
                        className={`${styles.hero__details__imageContainer} ${
                            altImageLayout
                                ? styles.hero__details__imageContainerNudged
                                : ""
                        }`}
                        delay="0.4s"
                    >
                        {image && image.length ? (
                            <GatsbyImage
                                fluid={
                                    mobileImage && mobileImage.length
                                        ? [
                                              generateFluid(
                                                  mobileImage[0],
                                                  "(min-width: 2200px) 37vw, (min-width: 1680px) 973px, (min-width: 600px) 59vw, 100vw",
                                                  "",
                                                  true
                                              ),
                                              generateFluid(
                                                  image[0],
                                                  "(min-width: 2200px) 37vw, (min-width: 1680px) 973px, (min-width: 600px) 59vw, 100vw",
                                                  "(min-width: 1024px)",
                                                  "",
                                                  true
                                              ),
                                          ]
                                        : [
                                              generateFluid(
                                                  image[0],
                                                  "(min-width: 2200px) 37vw, (min-width: 1680px) 973px, (min-width: 600px) 59vw, 100vw",
                                                  "",
                                                  true
                                              ),
                                          ]
                                }
                                loading="eager"
                                className={styles.hero__details__image}
                                style={{
                                    "--aspect-ratio": aspectRatioImage,
                                    "--aspect-ratio-mobile": aspectRatioImageMobile,
                                }}
                            />
                        ) : null}
                    </FadeUp>
                </div>
            </Container>
        </div>
    )

    const contentPricing = (
        <div className={`${styles.hero} ${styles.heroPricing}`}>
            <Container extended={true}>
                <Row>
                    <div className={styles.heroPricing__content}>
                        {pageTitle ? (
                            <FadeUp
                                className={`sub ${styles.heroPricing__content__pageTitle}`}
                            >
                                <p
                                    className={`sub ${styles.heroPricing__content__pageTitle}`}
                                >
                                    {pageTitle}
                                </p>
                            </FadeUp>
                        ) : null}
                        {title ? (
                            <FadeUp className="h1" delay="0.1s">
                                <h1>{title}</h1>
                            </FadeUp>
                        ) : null}
                        {description ? (
                            <FadeUp className="breakout" delay="0.2s">
                                <p className="breakout">{description}</p>
                            </FadeUp>
                        ) : null}
                    </div>
                </Row>
            </Container>
            <div className={styles.heroPricing__border} />
            <Container extended={true}>
                <Row>
                    <div className={styles.heroPricing__tabsWrapper}>
                        <div className={styles.heroPricing__tabsSpacing}>
                            <div className={styles.heroPricing__tabsInner}>
                                {tabs &&
                                    tabs.map((tab, i) => {
                                        const isActive = activeTab.id === tab.id
                                        return (
                                            <CategoryButton
                                                url={tab.url ?? tab.uri}
                                                text={tab.title}
                                                isActive={isActive}
                                                key={i}
                                            />
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                </Row>
            </Container>
        </div>
    )

    const contentLanding = (
        <div className={`${styles.hero} ${styles.heroLanding}`}>
            <Container extended={true}>
                <Row>
                    <div className={styles.heroLanding__content}>
                        {title ? (
                            <FadeUp className="h1">
                                <h1>{title}</h1>
                            </FadeUp>
                        ) : null}
                        {pageTitle ? (
                            <FadeUp
                                className={`sub ${styles.heroLanding__content__pageTitle}`}
                                delay="0.1s"
                            >
                                <p
                                    className={`sub ${styles.heroLanding__content__pageTitle}`}
                                >
                                    {pageTitle}
                                </p>
                            </FadeUp>
                        ) : null}
                        {description ? (
                            <FadeUp className="breakout" delay="0.2s">
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: description,
                                    }}
                                    className={styles.breakout}
                                />
                            </FadeUp>
                        ) : null}
                    </div>
                </Row>
            </Container>
        </div>
    )

    const getContent = () => {
        switch (type) {
            case "main":
                return contentMain
            case "productLanding":
                return contentProductLanding
            case "product":
                return contentProduct
            case "pricing":
                return contentPricing
            case "landing":
                return contentLanding
            default:
                return contentStandard
        }
    }

    return (
        <>
            <HeaderPopDown
                logo={smallLogo}
                theme="dark-extra-dark"
                productColor={productColor}
                howItWorksRef={howItWorksRef}
                heroRef={coverRef}
                productName={productName}
                updatePageState={updatePageState}
            />
            <Section
                ref={coverRef}
                className={`${styles.heroWrapper} ${
                    extended ? styles.heroWrapperExtended : ""
                } ${animation ? styles.heroWrapperAnimation : ""} ${
                    type === "main" ? styles.heroWrapperMain : ""
                } ${type === "pricing" ? styles.heroWrapperPricing : ""} ${
                    type === "landing" ? styles.heroWrapperLanding : ""
                }`}
                extended={extended}
                theme={theme}
                productColor={productColor}
            >
                {useBgPattern ? (
                    <BackgroundPattern
                        theme={theme}
                        autoZIndex={true}
                        productColor={productColor}
                    />
                ) : null}
                {bgImage ? (
                    <BackgroundImage
                        image={bgImage}
                        autoZIndex={true}
                        loading="eager"
                    />
                ) : null}

                {globeImageFluidData && type === "main" ? (
                    <div
                        className={styles.heroMainBackgroundImage}
                        style={{ "--opacity": heroMainBgOpacity }}
                    >
                        <GatsbyImage
                            fluid={globeImageFluidData}
                            alt={title}
                            loading="eager"
                        />
                    </div>
                ) : null}

                <Header
                    logo={logo}
                    theme={theme}
                    productColor={productColor}
                    productName={productName}
                />
                {getContent()}
            </Section>
        </>
    )
}

export default Hero
