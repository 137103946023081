import React, {
    useState,
    useEffect,
    useLayoutEffect,
    useCallback,
    useRef,
} from "react"
import Nav from "./Nav"
import LogoAnimated from "../LogoAnimated"
import styles from "./Header.module.scss"
import { Link } from "gatsby"
import getColors from "../../util/getColors"
import Row from "../layout/Row"
import FadeUp from "../effects/FadeUp"
import detectIt from "detect-it"
import LogoIcon from "../../svgs/LogoIcon"

const Header = ({
    popDown,
    logo,
    productName,
    theme,
    productColor,
    howItWorksRef,
    heroRef,
    updatePageState,
}) => {
    const themeColors = getColors(theme)

    const [isTouch, setIsTouch] = useState(false)
    const [isActive, setIsActive] = useState(false)

    const isScrollingUp = useRef(false)
    const lastScrollPos = useRef(0)
    const anchorScrollPos = useRef(0)
    const amountToScrollUpToTrigger = 50
    const noTriggerZone = useRef(700)
    const header = useRef()

    const getIntroHeight = useCallback(() => {
        if (howItWorksRef && heroRef) {
            const howItWorksHeight =
                howItWorksRef &&
                howItWorksRef.current &&
                howItWorksRef.current.clientHeight
            const heroHeight =
                heroRef && heroRef.current && heroRef.current.clientHeight

            const introHeight = howItWorksHeight + heroHeight

            noTriggerZone.current = introHeight
        }
    }, [howItWorksRef, heroRef])

    const handleResize = useCallback(() => {
        detectIt.update()
        setIsTouch(!detectIt.hasMouse)
        getIntroHeight()
    }, [getIntroHeight])

    const handleScroll = useCallback(() => {
        const currentScrollPos = window.scrollY
        const direction = currentScrollPos - lastScrollPos.current

        if (!isScrollingUp.current && direction < 0) {
            isScrollingUp.current = true
            anchorScrollPos.current = currentScrollPos
        } else if (direction >= 0) {
            isScrollingUp.current = false
            setIsActive(false)
        }

        const amountScrolledUp = anchorScrollPos.current - currentScrollPos

        if (
            isScrollingUp.current &&
            amountScrolledUp > amountToScrollUpToTrigger &&
            currentScrollPos >= noTriggerZone.current
        ) {
            setIsActive(true)
        } else {
            setIsActive(false)
        }

        lastScrollPos.current = currentScrollPos
    }, [])

    useLayoutEffect(() => {
        handleResize()
        setIsTouch(!detectIt.hasMouse)
    }, [handleResize])

    useEffect(() => {
        window.addEventListener("resize", handleResize)
        window.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("resize", handleResize)
            window.removeEventListener("scroll", handleScroll)
        }
    }, [handleResize, handleScroll])

    useEffect(() => {
        getIntroHeight()
    }, [getIntroHeight])

    useEffect(() => {
        lastScrollPos.current = window.scrollY
        anchorScrollPos.current = window.scrollY
    }, [])

    useEffect(() => {
        if (updatePageState) {
            const height = header.current?.offsetHeight
            updatePageState({ activeState: isActive, heightState: height })
        }
    }, [isActive, updatePageState])

    const productLogo = logo && logo.length && (
        <Link to="/" className={styles.header__logoContainer__logo}>
            <img
                src={logo[0].url}
                alt={productName}
                className={styles.header__logoContainer__logo__image}
            />
        </Link>
    )

    return !(popDown && isTouch) ? (
        <header
            className={`${styles.header} ${
                popDown ? styles.headerPopDown : ""
            } ${popDown && isActive ? styles.headerPopDownActive : ""}`}
            style={themeColors}
            ref={header}
        >
            <Row verticalCenter={true}>
                <div
                    className={`${styles.header__logoContainer} ${
                        logo && logo.length
                            ? styles.header__logoContainerCustomLogo
                            : ""
                    }`}
                >
                    {logo && logo.length ? (
                        popDown ? (
                            productLogo
                        ) : (
                            <FadeUp>{productLogo}</FadeUp>
                        )
                    ) : (
                        <Link
                            to="/"
                            className={styles.header__logoContainer__logo}
                        >
                            {popDown ? <LogoIcon /> : <LogoAnimated />}
                        </Link>
                    )}
                </div>
                <div className={styles.header__navContainer}>
                    <Nav
                        theme={theme}
                        productColor={productColor}
                        popDown={popDown}
                    />
                </div>
            </Row>
        </header>
    ) : null
}

export default Header
