import React, {useState, useEffect, useRef, Fragment, useCallback, useLayoutEffect} from "react";
import styles from "./Nav.module.scss";
import { graphql, useStaticQuery } from "gatsby"
import getColors from "../../../util/getColors";
import NavItem from "./NavItem";
import detectIt from "detect-it";
import LogoIcon from "../../../svgs/LogoIcon";
import Close from "../../../svgs/Close";
import { Link } from "gatsby";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import GradientButton from "../../elements/GradientButton";

const Nav = ({popDown, theme, productColor}) => {
    const menuData = useStaticQuery(graphql`
        {
            craft {
                primary: globalSet(handle:"primaryNavigation") {
                    ...on CRAFT_primaryNavigation_GlobalSet {
                        menu {
                            ...MenuContent
                        }
                    }
                }
                secondary: globalSet(handle:"secondaryNavigation") {
                    ...on CRAFT_secondaryNavigation_GlobalSet {
                        menu {
                            ...MenuContent
                        }
                    }
                }
            }
        }
    `);

    const primaryMenuData = menuData.craft.primary.menu;
    const secondaryMenuData = menuData.craft.secondary.menu;

    const themeColors = getColors(theme, productColor);
    const onBrightscanBg = theme === "brightscan-dark" || themeColors["--bg"].toUpperCase() === "#4731CE";
    const onTorusBg = theme === "torus-dark" || themeColors["--bg"].toUpperCase() === "#1CBBCC";

    const nav = useRef();
    const navInner = useRef();
    const navScrollContainer = useRef();

    const [open, setOpen] = useState(false);
    // const [contentHeight, setContentHeight] = useState(0);
    const [isTouch, setIsTouch] = useState(false);
    const [screenWidth, setScreenWidth] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [menuHeight, setMenuHeight] = useState("100vh");

    const showToggleMenu = (isTouch || screenWidth <= 768);

    const handleClick = (e) => {
        e.preventDefault();
        // const contentScrollHeight = navInner.current.scrollHeight;
        // setContentHeight(contentScrollHeight);
        if((showToggleMenu) && !open){
            disableBodyScroll(navScrollContainer.current);
        }else{
            enableBodyScroll(navScrollContainer.current);
        }
        
        setOpen(!open);
    };

    const handleNavigate = () => {
        if((!showToggleMenu) || open) enableBodyScroll(navScrollContainer.current);
        
        setOpen(!open);
    };

    const updateMaxHeight = useCallback(() => {
        setMenuHeight(window.innerHeight + "px");
    },[]);

    const handleResize = useCallback(() => {
        // const contentScrollHeight = navInner.current.scrollHeight;
        // setContentHeight(contentScrollHeight);
        detectIt.update();
        setIsTouch(!detectIt.hasMouse);
        setScreenWidth(window.innerWidth);
        if(!isTouch && window.innerWidth > 768) enableBodyScroll(navScrollContainer.current);
        updateMaxHeight();
    },[isTouch, updateMaxHeight]);

    useLayoutEffect(() => {
        handleResize();
        setIsTouch(!detectIt.hasMouse);
        setLoaded(true);
    }, [handleResize]);

    const disableScroll = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        nav.current.addEventListener("touchmove", disableScroll, {passive: true});

        const navSaved = nav.current;
        const navScrollContainerSaved = navScrollContainer.current;

        return (() => {
            window.removeEventListener("resize", handleResize);
            enableBodyScroll(navScrollContainerSaved);
            navSaved.removeEventListener("touchmove", disableScroll, {passive: true});
        });
    }, [handleResize]);

    return (
        <nav className={`${styles.nav} ${showToggleMenu ? styles.navTouch : styles.navNoTouch} ${popDown ? styles.navPopDown : ""}`} style={themeColors} ref={nav}>
            {loaded && (
                <>
                    <div className={styles.toggle}>
                        <button onClick={handleClick} aria-label="Open menu"><span></span><span></span><span></span></button>
                    </div>
                    <div className={`${styles.nav__scrollContainer} ${open && styles.nav__scrollContainerOpen}`} style={{height: open ? menuHeight : 0}} ref={navScrollContainer}>
                        <div className={styles.nav__inner} ref={navInner}>
                            <div className={styles.showMobile}>
                                <div className={styles.controls}>
                                    <div className={styles.logoSvg}>
                                        <Link to="/" onClick={handleNavigate}><LogoIcon /></Link>
                                    </div>
                                    <div className={styles.closeSvg}>
                                        <button onClick={handleClick} aria-label="Close menu"><Close /></button>
                                    </div>
                                </div>
                            </div>
                            <ul className={styles.nav__primary}>
                                {getNavItems(primaryMenuData, showToggleMenu, false, handleNavigate, popDown)}
                                {!popDown && <li className={styles.showDesktop}>
                                    <span className={styles.navLink}>
                                        <GradientButton
                                            text="Subscribe"
                                            url="https://myaccount.openavn.com/subscribe?prod=brightscan"
                                            external={true}
                                            className={styles.buttonWrapper}
                                            buttonClassName={styles.button}
                                            header={true}
                                            secondaryStyle={true}
                                            onBrightscanBg={onBrightscanBg}
                                            onTorusBg={onTorusBg}
                                        />
                                    </span>
                                </li>}
                            </ul>
                            <ul className={styles.nav__sub}>
                                {getNavItems(secondaryMenuData, showToggleMenu, true, handleNavigate, popDown)}
                            </ul>
                            <div className={`${!popDown ? styles.showMobile : ""} ${styles.buttonContainer}`}>
                                <GradientButton
                                    text="Subscribe"
                                    url="https://myaccount.openavn.com/subscribe?prod=brightscan"
                                    external={true}
                                    header={true}
                                    secondaryStyle={true}
                                    className={styles.buttonWrapper}
                                    buttonClassName={styles.button}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </nav>
    );
};

export default Nav;

const getNavItems = (list, isMobile, isSubNav, handleNavigate, popDown) => {
    const navItems = list && list.length ? list.map((item, i) => {
        return (
            <Fragment key={i}>
                <NavItem
                    link={item.page ? item.page[0] : item.externalLink} external={item.typeHandle === "externalLink"}
                    title={item.menuTitle}
                    showExternalIcon={item.showExternalIcon}
                    isMobile={isMobile}
                    isSubNav={isSubNav}
                    handleNavigate={handleNavigate}
                    popDown={popDown}
                />
            </Fragment>
        );
    }) : null;

    return navItems;
}


export const navFragment = graphql`
    fragment MenuContent on CRAFT_menu_MatrixField {
          ...on CRAFT_menu_pageLink_BlockType {
              typeHandle
              menuTitle
              page {
                  title
                  uri
                  children {
                      title
                      uri
                      ...on CRAFT_pages_externalLink_Entry {
                          externalLink {
                              url
                          }
                      }
                  }
              }
          }
          ...on CRAFT_menu_externalLink_BlockType {
              typeHandle
              externalLink {
                  type
                  url
                  target
                  text
              }
              showExternalIcon
          }
    }
`;