import React from "react";

const Close = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="close-icon">
            <rect y="16.0686" width="22.7244" height="1.33673" rx="0.5" transform="rotate(-45 0 16.0686)" />
            <rect x="0.94519" y="0.027832" width="22.7244" height="1.33673" rx="0.5" transform="rotate(45 0.94519 0.027832)" />
        </svg>
    );
};

export default Close;