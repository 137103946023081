import React from "react";

const LinkedInCircle = () => {
    return (
        <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg" className="linkedin-circle-icon">
            <path d="M18.022 0.681641C8.99628 0.681641 1.67969 7.99824 1.67969 17.024C1.67969 26.0497 8.99628 33.3663 18.022 33.3663C27.0477 33.3663 34.3643 26.0497 34.3643 17.024C34.3643 7.99824 27.0477 0.681641 18.022 0.681641ZM14.0215 23.7975H10.7122V13.1478H14.0215V23.7975ZM12.3465 11.8404C11.3012 11.8404 10.6254 11.0999 10.6254 10.184C10.6254 9.24944 11.3217 8.53106 12.389 8.53106C13.4564 8.53106 14.1101 9.24944 14.1305 10.184C14.1305 11.0999 13.4564 11.8404 12.3465 11.8404ZM26.1081 23.7975H22.7987V17.8956C22.7987 16.5218 22.3187 15.5889 21.1219 15.5889C20.2078 15.5889 19.6648 16.2205 19.4247 16.8282C19.3362 17.0444 19.3141 17.3508 19.3141 17.6555V23.7958H16.0031V16.5439C16.0031 15.2144 15.9605 14.1028 15.9162 13.1461H18.7915L18.943 14.6254H19.0094C19.4452 13.9308 20.5125 12.906 22.2983 12.906C24.4755 12.906 26.1081 14.3649 26.1081 17.5006V23.7975Z" />
        </svg>
    );
};

export default LinkedInCircle;