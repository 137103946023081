/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

function SEO({lang, meta, seomatic = {} }) {
  let parsedSeomatic = Object.keys(seomatic).reduce((acc, current) => {
    if(current !== "__typename") {
      acc[current] = JSON.parse(seomatic[current]);
    }
    return acc;
  },{} )
  let seomaticMetaTags = parsedSeomatic.metaTagContainer ? Object.values(parsedSeomatic.metaTagContainer).filter((val => !Array.isArray(val))) : [];
  seomaticMetaTags = seomaticMetaTags.map(tag => {
    if(tag.content.indexOf('/imager') !== -1) {
      tag.content = tag.content.replace(process.env.GATSBY_BASE_URL, process.env.GATSBY_ASSET_URL);
    }
    return tag;
  });
  let seomaticLinkTags = parsedSeomatic.metaLinkContainer ? Object.values(parsedSeomatic.metaLinkContainer).filter((val => !Array.isArray(val))) : [];


  let scripts = Object.values(parsedSeomatic.metaScriptContainer).map(script => {
    return {
      type: "text/javascript",
      innerHTML: script.script
    }
  });
  let noscripts = Object.values(parsedSeomatic.metaScriptContainer).map((script, i) => {
    return <noscript key={i} dangerouslySetInnerHTML={{__html: script.bodyScript.replace("<noscript>", "").replace("</noscript>", "")}} />
  })

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang
        }}
        title={parsedSeomatic.metaTitleContainer.title.title}
        meta={[].concat(seomaticMetaTags).concat(meta)}
        link={seomaticLinkTags}
        script={scripts}
      />
      {noscripts}
    </>

  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
