import React from "react";
import styles from "./Social.module.scss";
import FacebookCircle from "../../../svgs/FacebookCircle";
import TwitterCircle from "../../../svgs/TwitterCircle";
import InstagramCircle from "../../../svgs/InstagramCircle";
import LinkedInCircle from "../../../svgs/LinkedInCircle";
import getColors from "../../../util/getColors";

const SignUp = ({facebookUrl, twitterUrl, linkedinUrl, instagramUrl, theme}) => {
    const themeColors = getColors(theme);

    return (
        <div className={styles.social} style={themeColors}>
            {facebookUrl ? (
                <a href={facebookUrl} target="_blank" rel="nofollow noreferrer" aria-label="Facebook page"><FacebookCircle /></a>
            ) : null}
            {twitterUrl ? (
                <a href={twitterUrl} target="_blank" rel="nofollow noreferrer" aria-label="Twitter page"><TwitterCircle /></a>
            ) : null}
            {instagramUrl ? (
                <a href={instagramUrl} target="_blank" rel="nofollow noreferrer" aria-label="Instagram page"><InstagramCircle /></a>
            ) : null}
            {linkedinUrl ? (
                <a href={linkedinUrl} target="_blank" rel="nofollow noreferrer" aria-label="LinkedIn Page"><LinkedInCircle /></a>
            ) : null}
        </div>
    );
};

export default SignUp;