import React from "react"
import { Link } from "gatsby"
import fixCmsLink from "../../../util/fixCmsLink"

const LinkTo = ({
    url,
    external,
    children,
    className,
    target,
    fake,
    onClick,
    activeClassName,
}) => {
    let type = external || target === "_blank" ? "external" : "internal"

    if (fake) type = "fake"

    const link = () => {
        switch (type) {
            case "external":
                return (
                    <a
                        href={url}
                        target={target ? "_blank" : null}
                        rel="nofollow noreferrer"
                        className={className}
                        onClick={onClick}
                    >
                        {children}
                    </a>
                )
            case "fake":
                return <span className={`${className} button`}>{children}</span>
            default:
                return (
                    <Link
                        to={fixCmsLink(url)}
                        className={className}
                        activeClassName={activeClassName}
                        onClick={onClick}
                    >
                        {children}
                    </Link>
                )
        }
    }

    return url ? link() : null
}

export default LinkTo
