import React from "react"

const Logo = () => {
    return (
        <svg
            width="590"
            height="161"
            viewBox="0 0 590 161"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="logo"
        >
            <path d="M215 1.32031C232.06 1.32031 238 9.95032 238 19.9403V51.9403C238 61.8203 232.07 70.5603 215 70.5603H189.18V1.32031H215ZM202.28 59.0003H213.62C222.46 59.0003 224.85 55.4703 224.85 48.8103V23.0603C224.85 16.4003 222.46 12.8603 213.62 12.8603H202.28V59.0003Z" />
            <path d="M252.08 1.32031H294.51V12.8603H265.18V29.6103H292.18V41.1503H265.18V59.0003H294.51V70.5503H252.08V1.32031Z" />
            <path d="M307.85 70.5903V1.32031H350.7V12.8603H321V31.2703H348.46V42.8203H321V70.5903H307.85Z" />
            <path d="M363.1 1.32031H405.54V12.8603H376.21V29.6103H403.21V41.1503H376.21V59.0003H405.54V70.5503H363.1V1.32031Z" />
            <path d="M431.671 18.0603V70.5903H418.881V1.32031H436.351L462.661 54.0003V1.32031H475.461V70.5903H458.001L431.671 18.0603Z" />
            <path d="M510.161 71.6308C502.251 71.6308 493.101 69.6308 488.531 67.4708L490.811 57.1708C497.129 59.2354 503.725 60.3213 510.371 60.3908C519.831 60.3908 521.371 58.3908 521.371 51.7608C521.371 44.0608 521.161 43.4408 508.681 40.7608C491.941 37.0108 489.681 33.7608 489.681 19.1208C489.681 6.54078 495.401 0.300781 512.981 0.300781C519.767 0.337374 526.53 1.10513 533.151 2.59077L531.701 13.5908C525.619 12.2648 519.416 11.5678 513.191 11.5108C504.341 11.5108 502.791 13.3808 502.791 19.4208C502.791 27.3208 502.991 27.5308 514.951 30.4208C533.571 34.8908 534.511 37.9108 534.511 51.7408C534.501 64.8708 530.651 71.6308 510.161 71.6308Z" />
            <path d="M547.609 1.32031H589.999V12.8603H560.709V29.6103H587.709V41.1503H560.709V59.0003H589.999V70.5503H547.609V1.32031Z" />
            <path d="M214.1 91.2715H226.24L255.39 160.591H244.7L237 142.001H203.37L195.69 160.571H185L214.1 91.2715ZM233.1 132.551L220.19 101.321L207.29 132.551H233.1Z" />
            <path d="M302.59 91.2715C315.26 91.2715 324.05 99.8215 324.05 111.911C324.146 116.645 322.697 121.281 319.924 125.118C317.151 128.956 313.204 131.786 308.68 133.181L324.05 160.591H312.6L298 134.141H278.23V160.591H268.32V91.2715H302.59ZM278.23 124.721H300.42C308.05 124.721 313.95 119.841 313.95 112.341C313.95 105.191 308.44 100.691 301.29 100.691H278.23V124.721Z" />
            <path d="M371.471 117.131L399.751 160.591H387.861L364.511 124.191L350.451 137.191V160.591H340.541V91.2715H350.451V124.381L384.571 91.2715H398.571L371.471 117.131Z" />
            <path d="M17.0703 120.76H35.5603L102.81 120.71V120.65L93.6303 104.75H16.4103L16.4703 94.3605C16.5802 81.2043 20.0336 68.2921 26.506 56.8377C32.9785 45.3832 42.2573 35.7627 53.4703 28.8805L69.4703 1.23047L47.3203 13.9005C33.1842 22.052 21.4242 33.7568 13.2062 47.8545C4.98831 61.9521 0.597907 77.9529 0.470314 94.2705L0.320312 120.77L17.0703 120.76Z" />
            <path d="M35.1895 90.0002H53.7794L77.8495 48.1902L77.9194 48.0702L87.0695 32.1802L87.1494 32.0402L92.2794 23.1202L104.669 30.5002C115.267 37.504 123.983 47.0023 130.052 58.1611C136.121 69.32 139.359 81.7981 139.479 94.5002L154.159 119.93L154.679 120.82L155.429 122.12V120.82L155.489 95.3401C155.496 79.0233 151.239 62.9879 143.138 48.8237C135.038 34.6595 123.376 22.8584 109.309 14.5901L86.4695 1.16016L82.5394 8.00015L77.9095 16.0002L77.7995 16.1802L68.6694 32.0002L35.1895 90.0002Z" />
            <path d="M129.129 104.741L95.4595 46.4707L95.4095 46.5707L86.2294 62.4707L110.629 104.721L119.809 120.621L119.869 120.731L124.869 129.361L115.869 134.511C104.461 140.984 91.5977 144.46 78.4816 144.612C65.3654 144.764 52.4248 141.588 40.8695 135.381H8.93945L30.7295 148.001C44.8532 156.163 60.8649 160.493 77.1776 160.562C93.4903 160.63 109.538 156.435 123.729 148.391L146.729 135.271L138.319 120.731L129.129 104.741Z" />
        </svg>
    )
}

export default Logo
