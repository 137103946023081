import React from "react";

const Spinner = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style={{"marginRight":"-2px","display":"block","animationPlayState":"paused"}} className="spinner-icon" ><g transform="rotate(0 50 50)" style={{"transform":"matrix(1, 0, 0, 1, 0, 0)","animationPlayState":"paused"}} ><rect x="46.5" y="21.5" rx="3.5" ry="4.08" width="7" height="17" opacity="0.1" style={{"opacity":"0.1","animationPlayState":"paused"}} ></rect></g>
            <g transform="rotate(36 50 50)" style={{"transform":"matrix(0.809017, 0.587785, -0.587785, 0.809017, 38.9384, -19.8401)","animationPlayState":"paused"}} ><rect x="46.5" y="21.5" rx="3.5" ry="4.08" width="7" height="17" opacity="0.2" style={{"opacity":"0.2","animationPlayState":"paused"}} ></rect></g>
            <g transform="rotate(72 50 50)" style={{"transform":"matrix(0.309017, 0.951057, -0.951057, 0.309017, 82.102, -13.0037)","animationPlayState":"paused"}} ><rect x="46.5" y="21.5" rx="3.5" ry="4.08" width="7" height="17" opacity="0.3" style={{"opacity":"0.3","animationPlayState":"paused"}} ></rect></g>
            <g transform="rotate(108 50 50)" style={{"transform":"matrix(-0.309017, 0.951057, -0.951057, -0.309017, 113.004, 17.898)","animationPlayState":"paused"}} ><rect x="46.5" y="21.5" rx="3.5" ry="4.08" width="7" height="17" opacity="0.4" style={{"opacity":"0.4","animationPlayState":"paused"}} ></rect></g>
            <g transform="rotate(144 50 50)" style={{"transform":"matrix(-0.809017, 0.587785, -0.587785, -0.809017, 119.84, 61.0616)","animationPlayState":"paused"}} ><rect x="46.5" y="21.5" rx="3.5" ry="4.08" width="7" height="17" opacity="0.5" style={{"opacity":"0.5","animationPlayState":"paused"}} ></rect></g>
            <g transform="rotate(180 50 50)" style={{"transform":"matrix(-1, 1.22465e-16, -1.22465e-16, -1, 100, 100)","animationPlayState":"paused"}} ><rect x="46.5" y="21.5" rx="3.5" ry="4.08" width="7" height="17" opacity="0.6" style={{"opacity":"0.6","animationPlayState":"paused"}} ></rect></g>
            <g transform="rotate(216 50 50)" style={{"transform":"matrix(-0.809017, -0.587785, 0.587785, -0.809017, 61.0616, 119.84)","animationPlayState":"paused"}} ><rect x="46.5" y="21.5" rx="3.5" ry="4.08" width="7" height="17" opacity="0.7" style={{"opacity":"0.7","animationPlayState":"paused"}} ></rect></g>
            <g transform="rotate(252 50 50)" style={{"transform":"matrix(-0.309017, -0.951057, 0.951057, -0.309017, 17.898, 113.004)","animationPlayState":"paused"}} ><rect x="46.5" y="21.5" rx="3.5" ry="4.08" width="7" height="17" opacity="0.8" style={{"opacity":"0.8","animationPlayState":"paused"}} ></rect></g>
            <g transform="rotate(288 50 50)" style={{"transform":"matrix(0.309017, -0.951057, 0.951057, 0.309017, -13.0037, 82.102)","animationPlayState":"paused"}} ><rect x="46.5" y="21.5" rx="3.5" ry="4.08" width="7" height="17" opacity="0.9" style={{"opacity":"0.9","animationPlayState":"paused"}} ></rect></g>
            <g transform="rotate(324 50 50)" style={{"transform":"matrix(0.809017, -0.587785, 0.587785, 0.809017, -19.8401, 38.9384)","animationPlayState":"paused"}} ><rect x="46.5" y="21.5" rx="3.5" ry="4.08" width="7" height="17" opacity="1" style={{"animationPlayState":"paused"}} ></rect></g>
        </svg>
    );
};

export default Spinner;