import React from "react";
import styles from "./GradientButton.module.scss";
import LinkTo from "../LinkTo";

const GradientButton = ({text, url, external, target, filledStyle, primaryStyle, secondaryStyle, header, onBrightscanBg, onTorusBg, className, buttonClassName}) => {

    return text && url ? (
        <div className={`${className} ${styles.buttonWrapper}`}>
            <LinkTo
                className={`
                    ${buttonClassName}
                    ${styles.button}
                    ${header ? styles.buttonHeader : ""}
                    ${primaryStyle ? styles.buttonPrimary : ""}
                    ${secondaryStyle ? styles.buttonSecondary : ""}
                    ${onBrightscanBg ? styles.buttonOnBrightscan : ""}
                    ${onTorusBg ? styles.buttonOnTorus : ""}
                `}
                url={url}
                external={external}
                target={target}
            >
                <span className={styles.button__background}></span>
                <span className={styles.button__text}>{text}</span>
                {!filledStyle && <span className={styles.button__shine}></span>}
                {filledStyle && <span className={styles.button__bodyShine}></span>}
            </LinkTo>
        </div>
    ) : null;
};

export default GradientButton;