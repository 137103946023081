import React from "react";
import styles from "./Row.module.scss";

const Row = ({className, verticalCenter, verticalBottom, noWrapDesktop, children}) => {
    return (
        <div
            className={`
                ${styles.row}
                ${verticalCenter ? styles.rowVerticalCenter : ""}
                ${verticalBottom ? styles.rowVerticalBottom : ""}
                ${noWrapDesktop ? styles.rowNoWrapDesktop : ""}
                ${className}
            `}
        >
            {children}
        </div>
    );
};

export default Row;