import lightenDarkenColor from "./lightenDarkenColor";

const getColors = (theme, customColor = null) => {
    const colorDark = "#131128";
    const colorOpenAvnDark = "#1B1839";
    const colorWhite = "#ffffff";
    const colorCream = "#FBFAF5";
    const colorBrightscan = "#4731CE";
    const colorLightPurple = "#8673FF";
    const colorTorus = "#1CBBCC";
    const colorGrey = "#E4E4E4";
    const colorVeryLightGrey = "#F9F9F9";
    const colorBrightsparkPattern = "#4D39D9";
    const colorTorusPattern = "#53EEFF";
    const colorDarkPattern = "#221E46";
    const colorLightPattern = "#f4f2fc";
    const colorCreamPattern = "#FFFFFF";
    const colorVeryLightGrey2 = "#FBFBFB";

    switch (theme) {
        case "light":
            return {
                "--bg": colorWhite,
                "--bg-alt": colorCream,
                "--fg": colorOpenAvnDark,
                "--secondary": colorBrightscan,
                "--tertiary": colorGrey,
                "--subtle": colorVeryLightGrey,
                "--button-bg": colorWhite,
                "--pattern-main": colorLightPattern,
                "--pattern-sparks": lightenDarkenColor(colorLightPattern, 30),
                "--nav-hover": colorLightPurple,
                "--header-btn": colorBrightscan,
                "--btn-alt": colorBrightscan,
                "--icon-color": colorLightPurple,
                "--btn-hover": colorOpenAvnDark,
                "--btn-hover-fg": colorWhite,
                "--btn-hover-border": colorOpenAvnDark
            };
        case "cream":
            return {
                "--bg": colorCream,
                "--bg-alt": colorWhite,
                "--fg": colorOpenAvnDark,
                "--secondary": colorBrightscan,
                "--tertiary": colorGrey,
                "--subtle": colorVeryLightGrey,
                "--button-bg": colorWhite,
                "--pattern-main": colorCreamPattern,
                "--pattern-sparks": lightenDarkenColor(colorCreamPattern, 30),
                "--nav-hover": colorLightPurple,
                "--header-btn": colorBrightscan,
                "--btn-alt": colorBrightscan,
                "--icon-color": colorLightPurple,
                "--btn-hover": colorOpenAvnDark,
                "--btn-hover-fg": colorWhite,
                "--btn-hover-border": colorOpenAvnDark
            };
        case "dark":
            return {
                "--bg": colorOpenAvnDark,
                "--bg-alt": colorDark,
                "--fg": colorWhite,
                "--secondary": colorLightPurple,
                "--tertiary": lightenDarkenColor(colorDark, 50),
                "--subtle": colorOpenAvnDark,
                "--button-bg": colorDark,
                "--pattern-main": colorDarkPattern,
                "--pattern-sparks": lightenDarkenColor(colorDarkPattern, 30),
                "--nav-hover": colorLightPurple,
                "--header-btn": colorWhite,
                "--btn-alt": colorWhite,
                "--icon-color": colorBrightscan,
                "--btn-hover": colorBrightscan,
                "--btn-hover-fg": colorWhite,
                "--btn-hover-border": colorBrightscan
            };
        case "dark-alt":
            return {
                "--bg": colorOpenAvnDark,
                "--bg-alt": colorDark,
                "--fg": colorWhite,
                "--secondary": colorLightPurple,
                "--tertiary": colorWhite,
                "--subtle": colorOpenAvnDark,
                "--button-bg": colorWhite,
                "--pattern-main": colorDarkPattern,
                "--pattern-sparks": lightenDarkenColor(colorDarkPattern, 30),
                "--nav-hover": colorBrightscan,
                "--header-btn": colorWhite,
                "--btn-alt": colorWhite,
                "--icon-color": colorBrightscan,
                "--btn-hover": colorDark,
                "--btn-hover-fg": colorWhite,
                "--btn-hover-border": colorWhite
            };
        case "dark-extra-dark":
            return {
                "--bg": colorDark,
                "--bg-alt": colorOpenAvnDark,
                "--fg": colorWhite,
                "--secondary": colorLightPurple,
                "--tertiary": colorWhite,
                "--subtle": colorOpenAvnDark,
                "--button-bg": colorWhite,
                "--pattern-main": colorDarkPattern,
                "--pattern-sparks": lightenDarkenColor(colorDarkPattern, 30),
                "--nav-hover": colorLightPurple,
                "--header-btn": colorBrightscan,
                "--btn-alt": colorWhite,
                "--icon-color": colorBrightscan,
                "--btn-hover": colorDark,
                "--btn-hover-fg": colorWhite,
                "--btn-hover-border": colorWhite
            };
        case "brightscan-light":
            return {
                "--bg": colorWhite,
                "--bg-alt": colorVeryLightGrey2,
                "--fg": colorOpenAvnDark,
                "--secondary": colorBrightscan,
                "--tertiary": colorGrey,
                "--subtle": colorVeryLightGrey,
                "--button-bg": colorWhite,
                "--pattern-main": colorLightPattern,
                "--pattern-sparks": lightenDarkenColor(colorLightPattern, 30),
                "--nav-hover": colorLightPurple,
                "--header-btn": colorBrightscan,
                "--btn-alt": colorBrightscan,
                "--icon-color": colorBrightscan,
                "--btn-hover": colorOpenAvnDark,
                "--btn-hover-fg": colorWhite,
                "--btn-hover-border": colorOpenAvnDark
            };
        case "brightscan-dark":
            return {
                "--bg": colorBrightscan,
                "--bg-alt": colorBrightscan,
                "--fg": colorWhite,
                "--secondary": colorWhite,
                "--tertiary": colorWhite,
                "--subtle": colorVeryLightGrey,
                "--button-bg": colorBrightscan,
                "--pattern-main": colorBrightsparkPattern,
                "--pattern-sparks": lightenDarkenColor(colorBrightsparkPattern, 30),
                "--nav-hover": colorLightPurple,
                "--header-btn": colorWhite,
                "--btn-alt": colorWhite,
                "--icon-color": colorLightPurple,
                "--btn-hover": colorOpenAvnDark,
                "--btn-hover-fg": colorWhite,
                "--btn-hover-border": colorOpenAvnDark
            };
        case "brightscan-image":
            return {
                "--bg": colorDark,
                "--bg-alt": colorOpenAvnDark,
                "--fg": colorWhite,
                "--secondary": colorBrightscan,
                "--tertiary": colorWhite,
                "--subtle": colorVeryLightGrey,
                "--button-bg": colorWhite,
                "--pattern-main": colorBrightsparkPattern,
                "--pattern-sparks": lightenDarkenColor(colorBrightsparkPattern, 30),
                "--nav-hover": colorLightPurple,
                "--header-btn": colorWhite,
                "--btn-alt": colorBrightscan,
                "--icon-color": colorLightPurple,
                "--btn-hover": colorWhite,
                "--btn-hover-fg": colorOpenAvnDark,
                "--btn-hover-border": colorWhite
            };
        case "torus-light":
            return {
                "--bg": colorWhite,
                "--bg-alt": colorCream,
                "--fg": colorOpenAvnDark,
                "--secondary": colorTorus,
                "--tertiary": colorGrey,
                "--subtle": colorVeryLightGrey,
                "--button-bg": colorWhite,
                "--pattern-main": colorLightPattern,
                "--pattern-sparks": lightenDarkenColor(colorLightPattern, 30),
                "--nav-hover": colorLightPurple,
                "--header-btn": colorBrightscan,
                "--btn-alt": colorTorus,
                "--icon-color": colorTorus,
                "--btn-hover": colorOpenAvnDark,
                "--btn-hover-fg": colorWhite,
                "--btn-hover-border": colorOpenAvnDark
            };
        case "torus-dark":
            return {
                "--bg": colorTorus,
                "--bg-alt": colorTorus,
                "--fg": colorWhite,
                "--secondary": colorWhite,
                "--tertiary": colorWhite,
                "--subtle": colorVeryLightGrey,
                "--button-bg": colorTorus,
                "--pattern-main": colorTorusPattern,
                "--pattern-sparks": lightenDarkenColor(colorTorusPattern, 30),
                "--nav-hover": colorLightPurple,
                "--header-btn": colorWhite,
                "--btn-alt": colorWhite,
                "--icon-color": colorTorus,
                "--btn-hover": colorOpenAvnDark,
                "--btn-hover-fg": colorWhite,
                "--btn-hover-border": colorOpenAvnDark
            }
        case "torus-image":
            return {
                "--bg": colorDark,
                "--bg-alt": colorOpenAvnDark,
                "--fg": colorWhite,
                "--secondary": colorTorus,
                "--tertiary": colorWhite,
                "--subtle": colorVeryLightGrey,
                "--button-bg": colorWhite,
                "--pattern-main": colorTorusPattern,
                "--pattern-sparks": lightenDarkenColor(colorTorusPattern, 30),
                "--nav-hover": colorLightPurple,
                "--header-btn": colorWhite,
                "--btn-alt": colorTorus,
                "--icon-color": colorTorus,
                "--btn-hover": colorWhite,
                "--btn-hover-fg": colorOpenAvnDark,
                "--btn-hover-border": colorWhite
            };
        case "custom-light":
            return {
                "--bg": colorWhite,
                "--bg-alt": colorVeryLightGrey2,
                "--fg": colorOpenAvnDark,
                "--secondary": customColor ? customColor : colorBrightscan,
                "--tertiary": colorGrey,
                "--subtle": colorVeryLightGrey,
                "--button-bg": colorWhite,
                "--pattern-main": colorLightPattern,
                "--pattern-sparks": lightenDarkenColor(colorLightPattern, 30),
                "--nav-hover": colorLightPurple,
                "--header-btn": colorBrightscan,
                "--btn-alt": customColor ? customColor : colorBrightscan,
                "--icon-color": customColor ? customColor : colorBrightscan,
                "--btn-hover": colorOpenAvnDark,
                "--btn-hover-fg": colorWhite,
                "--btn-hover-border": colorOpenAvnDark
            };
        case "custom-dark":
            return {
                "--bg": customColor ? customColor : colorBrightscan,
                "--bg-alt": customColor ? customColor : colorBrightscan,
                "--fg": colorWhite,
                "--secondary": colorWhite,
                "--tertiary": colorWhite,
                "--subtle": colorVeryLightGrey,
                "--button-bg": customColor ? customColor : colorBrightscan,
                "--pattern-main": customColor ? lightenDarkenColor(customColor, 10) : colorBrightsparkPattern,
                "--pattern-sparks": customColor ? lightenDarkenColor(customColor, 30) : lightenDarkenColor(colorBrightsparkPattern, 30),
                "--nav-hover": colorLightPurple,
                "--header-btn": colorWhite,
                "--btn-alt": colorWhite,
                "--icon-color": customColor ? lightenDarkenColor(customColor, 60) : colorLightPurple,
                "--btn-hover": colorOpenAvnDark,
                "--btn-hover-fg": colorWhite,
                "--btn-hover-border": colorOpenAvnDark
            };
        case "custom-image":
            return {
                "--bg": colorDark,
                "--bg-alt": colorOpenAvnDark,
                "--fg": colorWhite,
                "--secondary": customColor ? customColor : colorBrightscan,
                "--tertiary": colorWhite,
                "--subtle": colorVeryLightGrey,
                "--button-bg": colorWhite,
                "--pattern-main": colorBrightsparkPattern,
                "--pattern-sparks": lightenDarkenColor(colorBrightsparkPattern, 30),
                "--nav-hover": colorLightPurple,
                "--header-btn": colorWhite,
                "--btn-alt": customColor ? customColor : colorWhite,
                "--icon-color": customColor ? customColor : colorLightPurple,
                "--btn-hover": colorWhite,
                "--btn-hover-fg": colorOpenAvnDark,
                "--btn-hover-border": colorWhite
            };
        default:
            return null;
    };
};

export default getColors;