import React from "react";

const Chevron = () => {
    return (
        <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg" className="chevron-icon">
            <path d="M13 1.25L7.88889 6.83572C7.64477 7.10258 7.32941 7.25 7.00267 7.25C6.67593 7.25 6.36056 7.10258 6.11644 6.83572L1 1.25" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    );
};

export default Chevron;