import React, { forwardRef } from "react"
import styles from "./Section.module.scss"
import getColors from "../../../util/getColors"

const Section = forwardRef(
    (
        {
            className,
            extended,
            split,
            fullLeftyRighty,
            noSpacing,
            minimalSpacing,
            reducedSpacingBottom,
            children,
            theme,
            productColor,
            overflowInitial,
            style,
        },
        ref
    ) => {
        const themeColors = getColors(theme, productColor)

        const sectionThemeClass = () => {
            switch (theme) {
                case "light":
                    return styles.sectionLight
                case "cream":
                    return styles.sectionCream
                case "dark":
                    return styles.sectionDark
                case "dark-alt":
                    return styles.sectionDarkAlt
                case "dark--extra-dark":
                    return styles.sectionDarkExtraDark
                case "brightscan-light":
                    return styles.sectionBrightscanLight
                case "brightscan-dark":
                    return styles.sectionBrightscanDark
                case "brightscan-image":
                    return styles.sectionBrightscanImage
                case "torus-light":
                    return styles.sectionTorusLight
                case "torus-dark":
                    return styles.sectionTorusDark
                case "torus-image":
                    return styles.sectionTorusImage
                case "custom-light":
                    return `${styles.sectionCustomLight} ${styles.sectionLight}`
                case "custom-dark":
                    return `${styles.sectionCustomDark} ${styles.sectionDark}`
                case "custom-image":
                    return styles.sectionCustomImage
                default:
                    return null
            }
        }

        return (
            <div
                className={`${styles.section} ${sectionThemeClass()} ${
                    extended ? styles.sectionExtended : ""
                } ${split ? styles.sectionSplit : ""} ${
                    fullLeftyRighty ? styles.sectionFullLeftyRighty : ""
                } ${
                    reducedSpacingBottom
                        ? styles.sectionReducedSpacingBottom
                        : ""
                }
                ${minimalSpacing ? styles.sectionMinimalSpacing : ""}  
                ${noSpacing ? styles.sectionNoSpacing : ""} 
                ${overflowInitial ? styles.sectionOverflowInitial : ""}
                ${className}`}
                style={{ ...themeColors, ...style }}
                ref={ref}
            >
                {children}
            </div>
        )
    }
)

export default Section
