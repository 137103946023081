import React, {useEffect, useState, useRef} from "react";
import SignUp from "./SignUp";
import Logo from "../../svgs/Logo";
import styles from "./Footer.module.scss";
import { graphql, Link, useStaticQuery } from "gatsby"
import getColors from "../../util/getColors";
import Button from "../elements/Button";
import Social from "./Social";
import LinkTo from "../elements/LinkTo";
import Chevron from "../../svgs/Chevron";
import External from "../../svgs/External";
import Container from "../layout/Container";
import Row from "../layout/Row";

const Footer = ({theme}) => {
    const themeColors = getColors(theme);
    const footerData = useStaticQuery(graphql`
        {
            craft {
                footer: globalSet(handle:"footer") {
                    ...on CRAFT_footer_GlobalSet {
                        menu {
                            ...MenuContent
                        }
                        miscLinks {
                            ...on CRAFT_miscLinks_BlockType {
                                subscribeLink
                                tryForFreeLink
                                facebook
                                twitter
                                instagram
                                linkedin
                                legalLinks {
                                    title
                                    uri
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const {menu, miscLinks} = footerData.craft.footer;

    const {facebook, instagram, legalLinks, linkedin, subscribeLink, tryForFreeLink, twitter} = miscLinks[0];


    const itemsContainerRef = useRef();

    const [open, setOpen] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);

    useEffect(() => {
        handleResize();

        window.addEventListener("resize", handleResize);

        return (() => {
            window.removeEventListener("resize", handleResize);
        })
    }, []);

    const handleClick = (e) => {
        e.preventDefault();
        const contentScrollHeight = itemsContainerRef && itemsContainerRef.current ? itemsContainerRef.current.scrollHeight : 0;
        setContentHeight(contentScrollHeight);
        setOpen(!open);
    };

    const handleResize = () => {
        const contentScrollHeight = itemsContainerRef && itemsContainerRef.current ? itemsContainerRef.current.scrollHeight : 0;
        setContentHeight(contentScrollHeight);
    };


    const legalLinksArr = legalLinks && legalLinks.length ? legalLinks.map((item,i) => {
        return item.uri ? (
            <Link key={i} to={item.uri}>{item.title}</Link>
        ) : null;
    }) : null;

    const linksArr = menu && menu.length ? menu.map((item, i) => {
        const hasChildren = item.page && item.page.length && item.page[0].children && item.page[0].children.length;

        const childrenArr = hasChildren ? item.page[0].children.map((child,j) => {
            return (
                child.uri ? (
                    <li key={j}>
                        <LinkTo
                            url={child.externalLink ? child.externalLink.url : child.uri}
                            external={child.externalLink}
                            target={child.externalLink ? "_blank" : ""}
                            className={styles.paragraphSub}
                        >
                            {child.title}{child.externalLink && (<span className={styles.linkSvg}>&#8239;<External /></span>)}
                        </LinkTo>
                    </li>
                ) : null
            );
        }) : null;

        return (item.page && item.page[0] && item.page[0].uri) || (item.externalLink && item.externalLink.url) ? (
            <li key={i}>
                <LinkTo
                    url={item.page && item.page.length ? item.page[0].uri : item.externalLink.url}
                    external={item.externalLink}
                    target={item.externalLink && item.externalLink.target ? item.externalLink.target : ""}
                >
                    {item.page && item.page.length ? item.page[0].title : item.externalLink.text}{item.showExternalIcon && (<span className={styles.linkSvg}>&#8239;<External /></span>)}
                </LinkTo>
                {hasChildren ? (
                    <ul>
                        {childrenArr}
                    </ul>
                ) : null}
            </li>
        ) : null;
    }) : null;

    return (
        <footer className={styles.footer} style={themeColors}>
            <Container className={styles.footer__links}>
                <Row noWrapDesktop={true} className={styles.rowSpacingBottom}>
                    <div className={`${styles.mobileRow} ${styles.footer__links__logo}`}>
                        <Link to="/" className={styles.logo}><Logo /></Link>
                    </div>
                    <div className={`${styles.mobileRow} ${styles.footer__links__connected}`}>
                        <p className="bold">Stay connected</p>
                        <SignUp />
                    </div>
                    <div className={styles.footer__links__follow}>
                        {facebook || instagram || twitter || linkedin ?
                            <>
                                <p className="bold">Follow OpenAVN</p>
                                <Social facebookUrl={facebook} instagramUrl={instagram} twitterUrl={twitter} linkedinUrl={linkedin} theme={theme} />
                            </>
                        : null}
                    </div>
                </Row>
                <Row noWrapDesktop={true}>
                    <div className={`${styles.footer__links__sitemap} ${styles.footer__links__sitemapDesktop}`}>
                        <ul className={styles.footer__links__sitemap__list}>
                            {linksArr}
                        </ul>
                    </div>
                    <div className={`${styles.mobileRow} ${styles.footer__links__sitemap} ${styles.footer__links__sitemapMobile}`}>
                        <button className={`${styles.heading} ${open ? styles.headingOpen : ""}`} onClick={handleClick} aria-label="Open sitemap">
                            <p className="bold">Sitemap</p>
                            <div><Chevron /></div>
                        </button>
                        <div className={styles.footer__links__sitemap__listWrapper} ref={itemsContainerRef} style={{height: open ? `${contentHeight}px` : 0}}>
                            <ul className={styles.footer__links__sitemap__list}>
                                {linksArr}
                            </ul>
                        </div>
                    </div>
                    <div className={`${styles.footer__links__cta}`}>
                        {subscribeLink || tryForFreeLink ?
                            <>
                                <p className="bold">Get Started</p>
                                {subscribeLink ? <Button url={subscribeLink} text="Subscribe" external="true" filledStyle={true} altColors={true} className={styles.button} /> : null}
                                {tryForFreeLink ? <Button url={tryForFreeLink} text="Try For Free" external="true" altColors={true} className={styles.button} /> : null}
                            </>
                        : null}
                    </div>
                </Row>
            </Container>
            <Container className={styles.footer__meta}>
                <Row verticalCenter={true} noWrapDesktop={true} className={styles.footer__meta__row}>
                    <div className={styles.footer__meta__copyright}>
                        <p className="meta">&copy; {new Date().getFullYear()} OpenAVN. All rights reserved.</p>
                    </div>
                    <div className={styles.footer__meta__legalLinks}>
                        <p className="meta">
                            {legalLinksArr}
                        </p>
                    </div>
                </Row>
            </Container>
        </footer>
    )
};

export default Footer;