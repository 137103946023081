export default function fixCmsLink(link) {
    let processedLink = link.replace(process.env.GATSBY_BASE_URL, "")
    processedLink =
        processedLink.substr(-1, 1) !== "/"
            ? processedLink + "/"
            : processedLink
    processedLink =
        processedLink.substr(0, 1) !== "/" ? "/" + processedLink : processedLink
    return processedLink
}
