export default function generateFluid(image, sizes = "", media = "", noBase64 = false) {
  if(!image || !image.responsiveImage) {
    return {};
  }
  return {
    aspectRatio: image.width / image.height,
    sizes: sizes,
    src: process.env.GATSBY_ASSET_URL + image.responsiveImage[image.responsiveImage.length - 1].url,
    srcSet: image.responsiveImage.map(image => {
      return `${process.env.GATSBY_ASSET_URL + image.url} ${image.width}w`
    }).join(','),
    base64: noBase64 ? "" : image.lqPlaceholder,
    srcWebp: image.responsiveImageWebp ? image.responsiveImageWebp[image.responsiveImageWebp.length - 1].url : undefined,
    srcSetWebp: image.responsiveImageWebp ? image.responsiveImageWebp.map(image => {
      return `${process.env.GATSBY_ASSET_URL + image.url} ${image.width}w`
    }).join(',') : undefined,
    media: media
  };
}