import React from "react";
import styles from "./BackgroundImage.module.scss";
import GatsbyImage from "gatsby-image";
import generateFluid from "../../../util/generateFluid";

const BackgroundImage = ({image, autoZIndex, gatsbyFile, loading, className}) => {
    return (
        image ? <div className={`${className} ${styles.backgroundImage} ${autoZIndex ? styles.backgroundImageAutoZIndex : ""}`}><GatsbyImage fluid={gatsbyFile ? image : generateFluid(image, "100vw")} loading={loading}/></div> : null
    );
};

export default BackgroundImage;