import React from "react";

const External = () => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" className="external-icon">
            <path d="M12.1456 4.48512V4.36012H12.0206L5.51488 4.36012L5.38988 4.36012L5.38988 4.48512L5.38988 5.43763L5.38988 5.56214L5.51439 5.56263L10.0627 5.5805L3.32158 12.3216L3.23319 12.41L3.32158 12.4984L4.00739 13.1842L4.09578 13.2726L4.18416 13.1842L10.9252 6.44308L10.9431 10.9914L10.9436 11.1159L11.0681 11.1159L12.0206 11.1159H12.1456V10.9909L12.1456 4.48512Z" strokeWidth="0.25"/>
        </svg>
    );
};

export default External;