import React from "react"
import Header from "../Header"

const HeaderPopDown = ({
    logo,
    productName,
    theme,
    productColor,
    howItWorksRef,
    heroRef,
    updatePageState,
}) => {
    return (
        <Header
            popDown={true}
            logo={logo}
            productName={productName}
            theme={theme}
            productColor={productColor}
            howItWorksRef={howItWorksRef}
            heroRef={heroRef}
            updatePageState={updatePageState}
        />
    )
}

export default HeaderPopDown
