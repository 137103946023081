import React from "react";

const TwitterCircle = () => {
    return (
        <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg" className="twitter-icon">
            <path d="M18.022 0.681641C8.99628 0.681641 1.67969 7.99824 1.67969 17.024C1.67969 26.0497 8.99628 33.3663 18.022 33.3663C27.0477 33.3663 34.3643 26.0497 34.3643 17.024C34.3643 7.99824 27.0477 0.681641 18.022 0.681641ZM24.6696 14.0687C24.6764 14.2083 24.6781 14.3479 24.6781 14.4841C24.6781 18.7399 21.442 23.6443 15.5213 23.6443C13.7032 23.6443 12.0111 23.1132 10.588 22.1973C10.8382 22.228 11.0953 22.2399 11.3557 22.2399C12.864 22.2399 14.2514 21.7275 15.3528 20.8627C13.9449 20.8355 12.7567 19.906 12.3465 18.6293C12.5422 18.665 12.7431 18.6854 12.9508 18.6854C13.2436 18.6854 13.5296 18.6463 13.7985 18.5731C12.326 18.2769 11.2161 16.9763 11.2161 15.417V15.3778C11.6502 15.6178 12.1473 15.7642 12.675 15.7813C11.8102 15.2025 11.2434 14.2185 11.2434 13.1001C11.2434 12.5111 11.4017 11.9561 11.6791 11.4812C13.2674 13.4287 15.6371 14.7088 18.3131 14.8467C18.2569 14.6101 18.2297 14.3649 18.2297 14.1113C18.2297 12.3358 19.6699 10.8922 21.4488 10.8922C22.3732 10.8922 23.2107 11.2837 23.798 11.9102C24.5317 11.7638 25.2194 11.4982 25.8408 11.1288C25.6008 11.8795 25.0918 12.5111 24.4262 12.9111C25.0782 12.8311 25.6978 12.6592 26.2749 12.4021C25.8442 13.0473 25.2978 13.6159 24.6696 14.0687Z" fill="white"/>
        </svg>
    );
};

export default TwitterCircle;