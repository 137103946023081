import React from "react";
import styles from "./Container.module.scss";

const Container = ({className, extended, style, children}) => {
    return (
        <div
            className={`${styles.container} ${extended ? styles.containerExtended : ""} ${className}`}
            style={style}
        >
            {children}
        </div>
    );
};

export default Container;